import { BASE } from "../actions";

export default function OverlayBackground({ children }) {
    return (
        <div
            className="h-screen w-full z-20 absolute top-0"
            style={{ backgroundImage: `url("${BASE}/bg.jpg")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
            {children}
        </div>
    )
}