import { List } from "phosphor-react";
import { freeCursor } from "../actions";
import useStore from "../store";

export default function MenuButton({}) {
    const setCurrentOverlay = useStore(state => state.setCurrentOverlay)

    const handleClick = () => {
        setCurrentOverlay("tutorial")
        freeCursor()
    }

    return(
        <div className="bg-red-500 text-black hover:text-white shadow-lg rounded-full fixed top-3 right-3 z-20 p-3" onClick={() => {handleClick()}}>
            <List />
        </div>
    )
}