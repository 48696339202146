import useStore from "../store"

export default function Link({src, title, artistname, year, index, link}) {
    const language = useStore(state => state.language)
    return(
        <div className="w-full flex gap-4 items-center">
            {index % 2 === 0 ? 
                <>
                    <img src={src} alt={`thumbnail für den film ${title} von ${artistname}"`} className="w-60 h-auto"/>
                    <div className="flex flex-col">
                        {/* <div>{title}</div> */}
                        <div>{artistname}</div>
                        <div>{year}</div>
                        <a href={link} className={"text-red-500 underline hover:text-black"} target="_blank" rel="noreferrer">{language === "de" ? "Auf Vimeo anschauen" : "Watch on Vimeo"}</a>
                    </div>
                </>:
                <>
                    <div className="flex flex-col">
                        {/* <div>{title}</div> */}
                        <div>{artistname}</div>
                        <div>{year}</div>
                        <a href={link} className={"text-red-500 underline hover:text-black"} target="_blank" rel="noreferrer">{language === "de" ? "Auf Vimeo anschauen" : "Watch on Vimeo"}</a>
                    </div>
                    <img src={src} alt={`thumbnail für den film ${title} von ${artistname}"`} className="w-60 h-auto"/>
                </>               
            }
        </div>
    )
}