import SwiperCore, {
    Keyboard,Scrollbar,Navigation,Pagination
  } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination'; // Pagination module
import "swiper/modules/effect-coverflow/effect-coverflow";
import useStore from '../store';
import { EffectCoverflow } from 'swiper';
import { useEffect, useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import BookPages from '../BookPages';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { BASE } from '../actions';

SwiperCore.use([Keyboard,Scrollbar,Navigation,Pagination]);


export default function BookOverlay() 
{
    const [imgSrc, setImgSrc] = useState(() => {return filledBook("de")});
    const [imgSrcENG, setImgSrcENG] = useState(() => {return filledBook("eng")});
    // const setBookEnabled = useStore(state => state.setBookEnabled);
    const currentOverlay = useStore(state => state.currentOverlay);
    const setCurrentOverlay = useStore(state => state.setCurrentOverlay);
    const [currentLanguage, setCurrentLanguage] = useState("de") 
    const bookRef = useRef()
    const setMainVolume = useStore(state => state.setMainVolume)

    function filledBook(language)
    {
        const newBook = []
        if(language === "de")
        {
            for(let i = 2; i < 75; i++)
            {
                newBook.push(`${BASE}/exhibit/book/de/seite${i}.jpg`)
            }
        }
        else if(language === "eng")
        {
            for(let i = 4; i < 74; i++)
            {
                newBook.push(`${BASE}/exhibit/book/eng/Abschluss__Seite_${i}.jpg`)
            }
        }
        return newBook;
    }

    const handleBackButtonClick = () => {
        // setBookEnabled(false);
        setCurrentOverlay("");
    }

    const handleLanguageSwitch = () => {
        if(currentLanguage === "de")
        {
            setCurrentLanguage("eng")
        }
        else if(currentLanguage === "eng")
        {
            setCurrentLanguage("de")
        }
    }

    // const nextButtonClick = () => {
    //     console.log(bookRef.current)
    //     // bookRef.current.getPageFlip().flipNext();
    //   };
    
    // const prevButtonClick = () => {
    //     console.log(bookRef.current)
    //     // bookRef.current.getPageFlip().flipPrev();
    // };

    // useEffect(() => {
    //     setImgSrc(() => {return filledBook(currentLanguage)})
    // }, [currentLanguage])

    // useEffect(() => {
    //     setMainVolume(currentOverlay==="book"?0:1)
    // }, [currentOverlay])

    return(
        <>
         <div className="absolute z-20 h-full justify-center gap-6 w-screen top-0 overflow-hidden bg-black flex flex-col">
            <div className="w-11/12 hull-full mx-auto">
                <Swiper
                    slidesPerView={2}
                    // slidesPerGroup={2}
                    slidesPerGroupSkip={1}
                    pagination={false}
                    navigation={{
                        nextEl: ".next",
                        prevEl: ".prev",
                    }}
                    centeredSlidesBounds={true}
                    loopAdditionalSlides={2}
                    spaceBetween={2}
                    // effect={"coverflow"}
                    grabCursor={true}
                    modules={[EffectCoverflow, Navigation]}
                >
                {currentLanguage === "de" && (imgSrc.map((src, index) => {
                    return(
                    <SwiperSlide key={index} className={"w-full h-full"}>                      
                            <img
                                src={src}
                                alt={src}
                                className={"w-full h-full"}    
                            />
                    </SwiperSlide>)
                }))}
                {currentLanguage === "eng" && (imgSrcENG.map((src, index) => {
                    return(
                    <SwiperSlide key={index} className={"w-full h-full"}>                      
                            <img
                                src={src}
                                alt={src}
                                className={"w-full h-full"}     
                            />
                    </SwiperSlide>)
                }))}
                </Swiper>
            </div>
            {/* {currentLanguage === "de" && <BookPages imgSrc={imgSrcDE}
            />}
            {currentLanguage === "eng" && <BookPages imgSrc={imgSrcENG}/>} */}
            <div className='w-4/5 mx-auto z-30 shrink-0'>

                <div className="flex text-white justify-between w-full">
                    <div className="prev cursor-pointer flex items-center gap-2">
                        <CaretLeft className='w-4 h-4'/> Vorherige Seite
                    </div>
                    <div className="cursor-pointer" onClick={handleBackButtonClick}>
                        Zurück
                    </div>
                    <div className="cursor-pointer" onClick={handleLanguageSwitch}>
                        {currentLanguage==="de" ? "English Version":"Deutsche Version"}
                    </div>
                    <div className="next cursor-pointer flex items-center gap-2">
                        Nächste Seite <CaretRight className='w-4 h-4'/>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}