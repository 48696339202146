import Book from './Book'
import RoomLight from './RoomLight'
import Table from './Table'
import Chair from './Chair'
import Lamp from './Lamp'

export default function Backroom() {
    return(
        <group position={[18,-4,-30]} rotation={[0,-Math.PI/2,0]}>
            <Book position={[0,.85,-0.5]}/>
            <RoomLight position={[.115,1.4,-1.96]}/>
            <Table position={[0,0,-2]}/>
            <Chair position={[.4,0,-1]}/>
            <Lamp position={[.6,.8,-2.2]}/>
        </group>
    )
}   