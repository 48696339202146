import {useEffect, useState} from "react"
import useStore from "../store"
import BookOverlay from "./BookOverlay"
import Credits from "./Credits"
import Crosshair from "./Crosshair"
import Linklist from "./Linklist"
import Menu from "./Menu"
import MenuButton from "./MenuButton"
import Tutorial from "./Tutorial"

export default function Overlay() {
    const currentOverlay = useStore(state => state.currentOverlay)

    return(
        <>
            {currentOverlay !== "" && currentOverlay !== "book" && <Menu />}
            {currentOverlay === "" && <MenuButton/>}
            {currentOverlay === "tutorial" && <Tutorial/>}
            {currentOverlay === "book" && <BookOverlay/>}
            {currentOverlay === "links" && <Linklist/>}
            {currentOverlay === "" && <Crosshair/>}
            {currentOverlay === "credits" && <Credits/>}
        </>
    )
}