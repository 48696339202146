import { X } from "phosphor-react"
import useStore from "../store"

export default function Crosshair({}) {
    const hovering = useStore(state => state.hovering)

    return(
        <div className="grid place-items-center absolute z-50 w-full h-screen pointer-events-none">
    
            <div className={`${hovering ? "w-20 h-20 border-6" : "w-0 h-0 border-none"} transition-all border-red-500`} style={{borderWidth: "2px"}}></div>
        
        </div>
    )
}