import { freeCursor } from "../actions"
import useStore from "../store"

export default function MenuItem({menuItem, children}) {
    const setCurrentOverlay = useStore(state => state.setCurrentOverlay)
    const currentOverlay = useStore(state => state.currentOverlay)

    const clickHandler = () => {
        setCurrentOverlay(menuItem)
    }

    return(
        <div className={`p-4 ${currentOverlay === menuItem ? "text-white hover:text-black underline":"hover:text-white cursor-pointer"}`} onClick={() => {clickHandler()}}>
            {children}
        </div>
    )
}