import './App.css';
import Scene from './components/Scene';
import Overlay from './components/overlay/Overlay';


function App() {
  return (
    <div className="App font">
      <Overlay />
       <Scene />
      {/* kkk */}
    </div>
  );
}

export default App;
