import { useTexture } from "@react-three/drei"
import { BASE } from "./actions"
import useStore from "./store"

export default function ChairMaterial() {

    const texture = useTexture(`${BASE}/exhibit/textures/chair.png`)
    texture.flipY = false

    const inBackRoom = useStore(state => state.inBackRoom)
    return(
        <meshStandardMaterial
            map={texture}
            color={inBackRoom?"white":"black"}
        />
    )
}