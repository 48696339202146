import { useEffect, useState } from "react"
import { sRGBEncoding, TextureLoader } from "three"
import { BASE } from "./actions";

export default function RoomMaterial() {

    const [texture, setTexture] = useState(new TextureLoader().load(`${BASE}/exhibit/textures/Baked7.jpg`, (tex) => {
        tex.flipY = false;
        // tex.encoding = sRGBEncoding;
    }))


    return(
        <meshBasicMaterial
            map={texture}
        />
    )
}