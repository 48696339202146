import useStore from "../store"
import LanguageSelection from "./LanguageSelection"
import MenuItem from "./MenuItem"

export default function Menu({}) {
    const language = useStore(state => state.language)

    return(
        <div className={"w-full px-6 fixed top-0 z-50 bg-red-500 flex justify-between "}>
            <div className="justify-center gap-8 flex">
                <MenuItem menuItem={"tutorial"}>
                    {language==="de"?"Intro":"Intro"}
                </MenuItem>
                {/* <div onClick={() => {useStore.getState().firstInteractionDone = true}}> */}
                    <MenuItem menuItem={""}>
                    {language==="de"?"Virtuelle Installation":"Virtual Installation"}
                    </MenuItem>
                {/* </div> */}
                {/* <MenuItem menuItem={"book"}>
                    Dokumente
                </MenuItem> */}
                <MenuItem menuItem={"links"}>
                    Links
                </MenuItem>
                <MenuItem menuItem={"credits"}>
                    Credits
                </MenuItem>
            </div>
            <LanguageSelection/>
        </div>
    )
}