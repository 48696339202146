import { PointerLockControls } from "@react-three/drei"
import useStore from "./store"

export default function Controls() {

    const currentOverlay = useStore(state => state.currentOverlay);
    const videoUrl = useStore(state => state.videoUrl)
    const setIsLocked = useStore(state => state.setIsLocked)
    
    return(
        <>
            {currentOverlay==="" && videoUrl === "" && <PointerLockControls
                onLock={() => {
                    setIsLocked(true);
                }}
                onUnlock={(e) => {
                    setIsLocked(false);
                }}
            />}
        </>
    )
}