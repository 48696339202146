import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BASE } from "./actions";
import LampMaterial from "./LampMaterial";

export default function Lamp({...props}) 
{
    const { nodes } = useLoader(GLTFLoader, `${BASE}/exhibit/lamp.glb`)
// rotation={[0,Math.PI+0.1,0]} scale={1.1}
    return(
        <>
            <mesh geometry={nodes.lamp.geometry} position={props.position} scale={.7}  rotation={[0,Math.PI/2 + .43,0]}>
                {/* <meshBasicMaterial/> */}
                <LampMaterial/>
            </mesh>
        </>
    )
}