import { useTexture } from "@react-three/drei"
import { useEffect, useState } from "react"
import { sRGBEncoding, TextureLoader } from "three"
import { BASE } from "./actions"
import useStore from "./store"

export default function TableMaterial() {

    const texture = useTexture(`${BASE}/exhibit/textures/Table_baseColor.jpeg`)
    texture.flipY = false

    const inBackRoom = useStore(state => state.inBackRoom)
    return(
        <meshStandardMaterial
            map={texture}
            // metalnessMap={mrTexture}
            // roughnessMap={mrTexture}
            color={inBackRoom?"white":"black"}
        />
    )
}