import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react"
import * as THREE from "three";
import useStore from "./store";

export default function Videomaterial({url, position}) {  
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.playsInline = true;
    vid.defaultMuted = true;
    vid.src = url;
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.autoplay = false;
    return vid
  })

  // console.log("video rerender", video.src)

  const pos = useRef(new THREE.Vector3(position[0], position[1], position[2])); 
  const {camera} = useThree();
  const MAX_DIST = 3;
  const setMainVolume = useStore(state => state.setMainVolume);

  const map = (value, iStart, iStop, oStart, oStop) => 
  {
    let val = oStart + (oStop - oStart) * ((value - iStart)/(iStop-iStart));
    if (val > oStart)
    {
      val = oStart;
    }
    else if(val < oStop)
    {
      val = oStop;
    }
    return val;
  } 

  useFrame((state, delta)=> {
    const dist = pos.current.distanceTo(camera.position);
    const popUpOpen = useStore.getState().videoUrl !== ""
    if(isVideoPlaying(video))
    {
      if(popUpOpen)
      {
        console.log("pop up open")
        video.pause();
        return;
      }
      video.volume = map(dist, MAX_DIST - .3, MAX_DIST, .1, 0);
      setMainVolume(.1-(video.volume));
      if(useStore.getState().currentOverlay !== "")
      {
        video.pause();
      }
    }
    if(dist < MAX_DIST && !isVideoPlaying(video) && !popUpOpen && useStore.getState().currentOverlay === "")
    {
      // console.log("now playing", url)
      video.play();
      video.muted = false;
    }
    else if(dist > MAX_DIST && isVideoPlaying(video))
    {
      video.pause();
      video.muted = true;
    }
  })

  const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

  useEffect(() => void video.play(), []);

  return(
    <meshStandardMaterial attach="material">
      <videoTexture attach="map" args={[video, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.NearestFilter, THREE.RGBAFormat]} />
    </meshStandardMaterial>
  )
}
