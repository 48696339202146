import { SpotLight } from "@react-three/drei";
import { useEffect, useRef } from "react";
import useStore from "./store";

export default function RoomLight({position})
{
    const spotlightRef = useRef()
    const inBackRoom = useStore(state => state.inBackRoom)

    useEffect(() => {
        spotlightRef.current.target.position.set( 19.85,  -4+.85, -30)
    }, [])

    return(
        <SpotLight 
            penumbra={0.5}
            // depthBuffer={depthBuffer}
            position={position}
            intensity={0.4}
            angle={0.05}
            color={inBackRoom?"white":"black"}
            castShadow
            anglePower={5}
            ref={spotlightRef}
            distance={.7}
    />
    )
}