import { CaretLeft, CaretRight, List } from "phosphor-react";
import { useState } from "react";
import useStore from "../store";
import OverlayBackground from "./OverlayBackground";

export default function Tutorial() {
    const [tutorialStep, setTutorialStep] = useState(0);
    const language = useStore(state => state.language)

    function handleNext() {
        if (tutorialStep < 7) setTutorialStep(old => old + 1)
    }

    function handlePrev() {
        if (tutorialStep > 0) setTutorialStep(old => old - 1)
    }

    return (
        <OverlayBackground>
            <div className="h-screen w-full grid place-items-center absolute top-0 z-30">
                <div className="border-white text-white border-2 px-5 pb-6 pt-3 flex flex-col gap-4 justify-center w-96">
                    <div className="h-32 flex flex-col justify-center">
                        {tutorialStep === 0 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Bevor du die virtuelle" :
                                        "Before you enter the virtual "}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "Videoinstallation betrittst, lese" :
                                        "installation, read the content"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "dir die Inhaltswarnung und" :
                                        "warning and instructions carefully."}
                                </div>
                                {language === "de" &&
                                    <div className="line-4 typed-out">
                                        Anleitung bitte genau durch.
                                    </div>}
                            </div>
                        }
                        {tutorialStep === 1 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Inhaltswarnung: Die Video-" :
                                        "Content warning: The video"}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "installation enthält Schilderungen" :
                                        "installation, contains depictions "}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "von Rassismus, Antisemitismus sowie" :
                                        "of racism, anti-Semitism as well"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "Verschwörungserzählungen." :
                                        "as conspiracy narratives."}
                                </div>
                            </div>
                        }
                        {tutorialStep === 2 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Darüber hinaus werden" :
                                        "In addition, National Socialist"}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "nationalsozialistische Verbrechen" :
                                        "crimes are thematized."}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "thematisiert." :
                                        ""}
                                </div>
                            </div>
                        }
                        {tutorialStep === 3 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Anleiung: Um dich in der" :
                                        "Instructions: To look around the "}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "Installation umzuschauen, bewege" :
                                        "installation, move the mouse."}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "die Maus." :
                                        ""}
                                </div>
                            </div>
                        }
                        {tutorialStep === 4 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Um nach vorne zu laufen, drücke die" :
                                        "To run forward, press"}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "Pfeil-nach-Oben oder die W Taste" :
                                        "the up arrow or the W key"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "auf deiner Tastatur." :
                                        "on your keyboard."}
                                </div>
                            </div>
                        }
                        {tutorialStep === 5 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Um nach hinten zu laufen, drücke" :
                                        "To run to the back, press"}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "die Pfeil-nach-Unten oder S Taste" :
                                        "the down arrow or S key"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? "auf deiner Tastatur." :
                                        "on your keyboard."}
                                </div>
                            </div>}
                        {tutorialStep === 6 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? "Um weitere Informationen zu der" :
                                        "To get more information about the"}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "Installation zu erhalten, drücke" :
                                        "installation press the ESC key on"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? <div>die ESC-Taste auf deiner Tastatur</div> :
                                        <div>your keyboard and click the <div className="inline bg-red-500 rounded-full p-2"><List className="inline" /></div></div>}
                                </div>
                                <div className="line-4 typed-out">
                                    {language === "de" ? <div>  und klicke auf das <div className="inline bg-red-500 rounded-full p-2"><List className="inline" /></div> Icon.</div> :
                                        "icon in the top right corner."}
                                </div>
                            </div>
                        }
                        {tutorialStep === 7 &&
                            <div className="text-left">
                                <div className="typed-out">
                                    {language === "de" ? <div>Wenn das Interaktionssymbol (<div className="inline pr-4 border-2 border-red-500" />)</div> :
                                        <div>When the interaction symbol (<div className="inline pr-4 border-2 border-red-500" />)</div>}
                                </div>
                                <div className="line-2 typed-out">
                                    {language === "de" ? "erscheint, kannst du mit der linken" :
                                        "appears, you can interact with"}
                                </div>
                                <div className="line-3 typed-out">
                                    {language === "de" ? <div>Maustaste interagieren.</div> :
                                        <div>the left mouse button.</div>}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex flex-row w-full justify-center gap-10">
                        <div onClick={handlePrev} className={`${tutorialStep === 0 ? "text-transparent pointer-events-none" : "text-white"} cursor-pointer`}>
                            <CaretLeft className="w-6 h-6" />
                        </div>
                        <div onClick={() => { handleNext() }} className={`${tutorialStep === 7 ? "text-transparent pointer-events-none" : "text-white"} cursor-pointer`}>
                            <CaretRight className="w-6 h-6" />
                        </div>
                    </div>
                </div>
            </div>
        </OverlayBackground>
    )
}