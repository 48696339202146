import { useTexture } from "@react-three/drei"
import { useEffect, useState } from "react"
import { DoubleSide } from "three"
import { sRGBEncoding, TextureLoader } from "three"
import { BASE } from "./actions"
import useStore from "./store"

export default function LampMaterial() {

    const texture = useTexture(`${BASE}/exhibit/textures/lamp.png`)
    texture.flipY = false

    const inBackRoom = useStore(state => state.inBackRoom)
    return(
        // <meshMatcapMaterial
        //     map={texture}
        //     // metalnessMap={mrTexture}
        //     // roughnessMap={mrTexture}
        //     // color={inBackRoom?"white":"black"}
        // />
        <meshMatcapMaterial
            attach="material"
            args={[
                {
                    color: 0xffffff,
                    map: texture,
                    side: DoubleSide,
                }
            ]}>
        </meshMatcapMaterial>
    )
}