/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Videomaterial from './Videomaterial'
import useStore from './store'
import { BASE } from './actions'

export default function VideoModels(props) {
  const group = useRef();
  const { nodes } = useLoader(GLTFLoader, `${BASE}/exhibit/videos.glb`);
  const setVideoUrl = useStore(state => state.setVideoUrl);

  const material = new THREE.MeshStandardMaterial({color: 0xFFFFFF});

  function handleVideoClick(url) {
    //   setVideoUrl(url);
    //   setTimeout(
    //     () => {
    //         document.exitPointerLock();
    //     }, 1000);
    window.open(url, '_blank');
  }
  console.log("rerender videos")

  return (
    <>
        <group ref={group} {...props} dispose={null}>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Video01.geometry}
            position={[-4.9, 2, -3.5]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[1.44, 1.44, 1.44]}
            // onClick={() => {handleVideoClick("/exhibit/video/Aya.mp4")}}
        >
            <Videomaterial url={`${BASE}/exhibit/video/Aya.mp4`} position={[-4.9, 2, -3.5]}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Video02.geometry}
            material={material}
            position={[4.9, 2, -9.5]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[0.43, 4.54, 0.79]}
            // onClick={() => {handleVideoClick("/exhibit/video/Nuri.mp4")}}
        >
             <Videomaterial url={`${BASE}/exhibit/video/Nuri.mp4`} position={[4.9, 2, -9.5]}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Video03.geometry}
            material={material}
            position={[-4.9, 2, -15.5]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[1.2, 2.31, 0.68]}
            // onClick={() => {handleVideoClick("/exhibit/video/Rehtanz.mp4")}}
        >
            <Videomaterial url={`${BASE}/exhibit/video/Rehtanz.mp4`} position={[-4.9, 2, -15.5]}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Video04.geometry}
            material={material}
            position={[4.9, 2, -21.5]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={[1.27, 2.44, 0.71]}
            // onClick={() => {handleVideoClick("/exhibit/video/elena.mp4")}}
        >
            <Videomaterial url={`${BASE}/exhibit/video/elena.mp4`} position={[4.9, 2, -21.5]}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Video05.geometry}
            material={material}
            position={[0.01, 2, -32.8]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.92, 3.69, 1.08]}
            // onClick={() => {handleVideoClick("/exhibit/video/salzmann.mp4")}}
        >
            <Videomaterial url={`${BASE}/exhibit/video/salzmann.mp4`} position={[0.01, 2, -32.8]}/>
        </mesh>
        </group>
    </>
  )
}