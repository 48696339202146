// import MainAudio from "./MainAudio"
import RoomCanvas from "./RoomCanvas"
import VideoOverlay from "./VideoOverlay"
import useStore from "./store"

export default function Scene() {
    const setAudioStarted = useStore(state => state.setAudioStarted)
    console.log("rerender scene")
    return(
        <div className="h-screen absolute top-0 w-full z-0" onClick={setAudioStarted(true)}>
            <RoomCanvas />
            {/* <video controls autoplay>
                <source src="video/salzmann.mp4" type="video/mp4"/>
            </video> */}
            {/* <MainAudio /> */}
            {/* <VideoOverlay /> */}
        </div>
    )
}