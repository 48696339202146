import * as THREE from "three";
import { Octree } from "three/examples/jsm/math/Octree";
import { Capsule } from "three/examples/jsm/math/Capsule";
import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
// import { isMobile } from "react-device-detect";
// import useStore from "../../../../helpers/store";
import { PositionalAudio, Stats } from "@react-three/drei";
import useStore from "./store";
import { BASE } from "./actions";
// import MobileRotator from "./MobileRotator"

const FPSControls = ({ world }) => {
  // const showModal = useStore((state) => state.showModal);
  // const mobileControlType = useStore((state) => state.mobileControlType);
  // const setMainVolume = useStore(state => state.setMainVolume);
  const characterRef = useRef();
  const { camera } = useThree();
  const GRAVITY = 100;
  const STEPS_PER_FRAME = 5;
  const setupDone = useRef(false);
  const [worldOctree, setWorldOctree] = useState(new Octree());
  const [playerCollider] = useState(new Capsule(new THREE.Vector3(0, 2, 0), new THREE.Vector3(0, 3.3, 0), 0.35));
  const playerVelocity = new THREE.Vector3();
  const playerDirection = new THREE.Vector3();
  const setFirstInteractionDone = useStore(state => state.setFirstInteractionDone)
  const currentOverlay = useStore(state => state.currentOverlay)
  const setInBackRoom = useStore(state => state.setInBackRoom)
  const inBackRoom = useRef(false)

  const playerOnFloor = useRef(false);

  const keyStates = {};
  document.addEventListener( 'keydown', ( event ) => {
    keyStates[ event.code ] = true;
  } );
  document.addEventListener( 'keyup', ( event ) => {
    keyStates[ event.code ] = false;
  } );

  useEffect(() => {
    worldOctree.fromGraphNode(world);
    setupDone.current = true;
    camera.rotateX(1.3);
    camera.rotateY(Math.PI)
  }, [])
  // const { forward, backward, left, right, jump } = usePlayerControls();

  function remap(value, start1, stop1, start2, stop2) {
    return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
  }

  function playerCollisions() {
    const result = worldOctree.capsuleIntersect(playerCollider);
    if(result === null) return;
    // console.log(result);
    playerOnFloor.current = false;

    if (result) {
      playerOnFloor.current = result.normal.y > 0;
      if (!playerOnFloor.current) {
        playerVelocity.addScaledVector(result.normal, - result.normal.dot(playerVelocity));
      }
      playerCollider.translate(result.normal.multiplyScalar(result.depth));
    }
  }

  function updatePlayer(deltaTime) {
    // console.log("playerVel", playerVelocity, "onFloor", playerOnFloor.current, "berechnung", deltaTime)
    // Slide, je niedrieger die exponent Zahl, desto höher die Reibung
    const damping = Math.exp(- 10 * deltaTime) - 1;
    playerVelocity.addScaledVector(playerVelocity, damping);
    if (playerOnFloor.current) {


    } else {

      playerVelocity.y -= GRAVITY * deltaTime;

    }
    // console.log("playerVelAfter", playerVelocity)
    const deltaPosition = playerVelocity.clone().multiplyScalar(deltaTime);
    // console.log("delta",deltaPosition)
    playerCollider.translate(deltaPosition);

    playerCollisions();

    camera.position.copy(playerCollider.end);
  }

  function getForwardVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();

    return playerDirection;
  }

  function getSideVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();
    playerDirection.cross(camera.up);

    return playerDirection;
  }

  function controls(deltaTime) {
    const speed = 20;
    if (keyStates[ 'KeyW' ] | keyStates["ArrowUp"]) {
      playerVelocity.add(getForwardVector().multiplyScalar(speed * deltaTime));
      setFirstInteractionDone(true)
    }
    if (keyStates[ 'KeyS' ]| keyStates["ArrowDown"]) {
      playerVelocity.add(getForwardVector().multiplyScalar(- speed * deltaTime));
    }
    if (keyStates[ 'KeyA' ]| keyStates["ArrowLeft"]) {
      playerVelocity.add(getSideVector().multiplyScalar(- speed * deltaTime));
    }
    if (keyStates[ 'KeyD' ]| keyStates["ArrowRight"]) {
      playerVelocity.add(getSideVector().multiplyScalar(speed * deltaTime));
    }
    // if (playerOnFloor.current) {
    //   if (keyStates[ 'Space' ]) {
    //     playerVelocity.y = 20;
    //   }
    // }
  }

  useFrame((state,delta) => {
    const deltaTime = Math.min( 0.05, delta ) / STEPS_PER_FRAME;
    characterRef.current.position.copy(camera.position);
    if(currentOverlay !== "") return
    if(setupDone.current === true)
    {
      for ( let i = 0 ; i < STEPS_PER_FRAME ; i ++ ) {
        controls( deltaTime );
        updatePlayer( deltaTime );
      }
      //TODO: check if player is out of bounds
    }
    // console.log("camZ", camera.position.z)
    if(camera.position.y < -1.6)
    {
      // setMainVolume(0)
      if(!inBackRoom.current)
      {
        setInBackRoom(true)
        inBackRoom.current = true
      }
    }
    else 
    {
      if(inBackRoom.current)
      {
        setInBackRoom(false)
        inBackRoom.current = false
      }
    }
  })

  return (
    <mesh ref={characterRef} name="characters">
      <PlayerAudio />
    </mesh>
  );
};


export const PlayerAudio = ({ walkingSounds = true }) => {
  const bgAudio = useRef(null);
  const bgAudioBack = useRef(null)
  const playingBackAudio = useRef(false)
  const mainVolume = useStore(state => state.mainVolume);

  useEffect(() => {
    const bgAudioR = bgAudio.current;
    const bgAudioBackR = bgAudioBack.current;
    return () => [bgAudioR, bgAudioBackR].forEach((sound) => sound && sound.isPlaying && sound.stop());
  }, []);

  useEffect(() => {
    bgAudio.current.setVolume(mainVolume)
    bgAudioBack.current.setVolume(mainVolume)
    console.log("main volume", mainVolume)
  }, [mainVolume])

  useFrame((_, delta) => {
    if (
      useStore.getState().firstInteractionDone &&
      useStore.getState().currentOverlay === "" 
    ) {
        if (!bgAudio.current.isPlaying && !playingBackAudio.current) {
          bgAudio.current.setVolume(mainVolume);
          bgAudioBack.current.setVolume(mainVolume)
          bgAudio.current.play();
          console.log("starting audio")
        }
        if(useStore.getState().inBackRoom && !playingBackAudio.current)
        {
          bgAudioBack.current.play()
          bgAudio.current.pause()
          playingBackAudio.current = true
          console.log("starting back")
        }
        if(!useStore.getState().inBackRoom && playingBackAudio.current)
        {
          bgAudioBack.current.pause()
          bgAudio.current.play()
          playingBackAudio.current = false
        }
      } else {
      // console.log("playing", useStore.getState().currentOverlay, useStore.getState().firstInteractionDone)
      if(bgAudio.current.isPlaying) {
      bgAudio.current.stop();
      }
      if(bgAudioBack.current.isPlaying)
      {
      bgAudioBack.current.stop();
      }
      if(playingBackAudio.current)
      {
        playingBackAudio.current = false
      }
    }
  });
  return (
    <>
      <PositionalAudio ref={bgAudio} url={`${BASE}/exhibit/sound/salzmann.mp3` } autoplay loop distance={1} />
      <PositionalAudio ref={bgAudioBack} url={`${BASE}/exhibit/sound/sound_back.mp3`} autoplay loop distance={1} />
      {/* <PositionalAudio ref={musicAudio} url={randomSound} autoplay loop distance={1} /> */}
    </>
  );
};
export default FPSControls;