import { BASE } from "../actions";
import useStore from "../store";
import Link from "./Link";
import OverlayBackground from "./OverlayBackground";

export default function Linklist() {
    const language = useStore(state => state.language)
    return (
        <OverlayBackground>
            <div className="w-full h-screen grid place-items-center absolute top-0 py-28 z-30">
                <div className="flex flex-col overflow-y-scroll h-full gap-12 overflow-x-hidden px-28 border-white text-white border-2 py-8">
                    {language === "de" ? "Zusatzmaterial" : "Additional material"}
                    <Link src={`${BASE}/exhibit/thumbnail06.jpg`} title="ohne Titel" artistname={"Anja Lang"} index={0} year={2023} link={"https://vimeo.com/846629329?share=copy"} />
                    {language === "de" ? "Virtuelle Installation" : "Virtual installation"}
                    <Link src={`${BASE}/exhibit/thumbnail01.jpg`} title="ohne Titel" artistname={"Aya Limbacher"} index={1} year={2021} link={"https://vimeo.com/574977411"} />
                    <Link src={`${BASE}/exhibit/thumbnail03.jpeg`} title="ohne Titel" artistname={"Nuria Rojas Castañeda"} index={2} year={2021} link={"https://vimeo.com/563321381"} />
                    <Link src={`${BASE}/exhibit/thumbnail04.jpeg`} title="ohne Titel" artistname={"Herr v. Rehtanz"} index={3} year={2021} link={"https://vimeo.com/494957949"} />
                    <Link src={`${BASE}/exhibit/thumbnail05.jpeg`} title="ohne Titel" artistname={"Elena Padva"} index={4} year={2021} link={"https://vimeo.com/595617882"} />
                    <Link src={`${BASE}/exhibit/thumbnail02.jpeg`} title={language === "de" ? "Salzmann Fabrik" : "Salzmann Factory"} artistname={language === "de" ? "Salzmann Fabrik" : "Salzmann Factory"} index={5} year={2021} link={"https://vimeo.com/597855561"} />
                </div>
            </div>
        </OverlayBackground>
    )
}