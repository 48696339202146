import Vimeo from "@u-wave/react-vimeo";
import OverlayBackground from "./OverlayBackground";

export default function Credits() {
    return(
        <OverlayBackground>
            <div className="w-full h-screen grid place-items-center absolute top-0 overflow-auto py-28 z-30 text-left px-24 text-white">
                <div className="flex flex-col gap-4 justify-center items-center ">
                    <div className="w-full mb-24">
                        <Vimeo video={"736160103"} responsive/>
                    </div>
                    <div className="text-red-500 text-xl">Assets</div>
                    <div>
                        "Console Table" (https://skfb.ly/o6rK9) by AHMED KCHIKICH is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                    </div>
                    <div>
                        "Old White School Chair" (https://skfb.ly/o7WSx) by aurelkillers1 is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                    </div>
                    <div>
                        "Lamp" (https://skfb.ly/o8zH9) by Metro_Map is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                    </div>

                </div>
            </div>
        </OverlayBackground>
    )
}