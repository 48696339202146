import { useLoader } from "@react-three/fiber";
import { Vector2 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BASE, freeCursor } from "./actions";
import BookMaterial from "./BookMaterial";
import useStore from "./store";

export default function Book({...props}) 
{
    const { nodes, scene } = useLoader(GLTFLoader, `${BASE}/exhibit/buch.glb`)
    const setCurrentOverlay = useStore(state => state.setCurrentOverlay)
    const setHovering = useStore(state => state.setHovering)

    const handleBookClick = () => {
        setCurrentOverlay("book")
        freeCursor();
    }

    const handleMouseEnter = () => {
        setHovering(true)
    }

    const handleMouseLeave = () => {
        setHovering(false)
    }

    return(
        <>
            <mesh 
                geometry={nodes.Buch.geometry} 
                rotation={[-Math.PI/2,0,0]} 
                position={props.position} 
            >
                <BookMaterial />
            </mesh>
            <mesh
                position={[props.position[0], props.position[1], props.position[2]-1.3]}
                onClick={() => {handleBookClick()}}
                onPointerEnter={() => {handleMouseEnter()}}
                onPointerLeave={() => {handleMouseLeave()}}
                visible={false}
            >
                <boxGeometry/>
            </mesh>
        </>
    )
}