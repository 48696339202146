import { Canvas } from '@react-three/fiber';
import Room from './Room';
import Fallback from './Fallback';
import { Suspense, useRef} from 'react';
import Controls from './Controls';
import useStore from './store';

export default function RoomCanvas() {

    return(
        <Suspense fallback={<Fallback />}>
            <Canvas 
                camera={{position: [0,2,-2], near: 0.005, far: 500}}
                raycaster={{
                    computeOffsets: (_, { size: { width, height } }) => {
                        if (useStore.getState().isLocked) {
                            return {
                            offsetX: width / 2,
                            offsetY: height / 2,
                            };
                        } else {
                            return null;
                    }
                }}}
            >
                {/* <Stats /> */}
                <Room position={[0,0,0]}/>
                <Controls />
                <ambientLight intensity={.8} scale={100} position={[0, 5, 0]} />
            </Canvas>
        </Suspense>
    )
}
