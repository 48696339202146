/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import FPSControls from './FPSControls'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import RoomMaterial from './RoomMaterial'
import VideoModels from './VideoModels'
import { BASE } from './actions'
import Backroom from './Backroom'

export default function Room(props) {
  const group = useRef()

  const { nodes, scene } = useLoader(GLTFLoader, `${BASE}/exhibit/side_room.glb`)


  return (
    <>
      <FPSControls world={scene}/>
      <group ref={group} {...props} dispose={null}>
        <mesh geometry={nodes.Room.geometry} material={nodes.Room.material}>
          <RoomMaterial />
        </mesh>
        <VideoModels />
        <Backroom />
    </group>
    </>
  )
}
