import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BASE } from "./actions";
import ChairMaterial from "./ChairMaterial";

export default function Chair({...props}) 
{
    const { nodes } = useLoader(GLTFLoader, `${BASE}/exhibit/chair.glb`)

    return(
        <>
            <mesh geometry={nodes.chair.geometry} position={props.position} rotation={[0,Math.PI+0.1,0]} scale={1.1}>
                <ChairMaterial />
            </mesh>
        </>
    )
}