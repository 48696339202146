import useStore from "../store"

export default function LanguageSelection() {
    const setLanguage = useStore(state => state.setLanguage)
    const language = useStore(state =>state.language)

    const handleClick = (value) => {
        setLanguage(value)
    }

    return(
        <div className="flex p-4 items-center justify-self-end cursor-default">
            <div className={`${language === "de" ? "text-white" : "text-black hover:text-white cursor-pointer"}`} onClick={() => {handleClick("de")}}>DE</div>
            /
            <div className={`${language === "en" ? "text-white" : "text-black hover:text-white cursor-pointer"}`} onClick={() => {handleClick("en")}}>EN</div>
        </div>
    )
}