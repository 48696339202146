import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BASE } from "./actions";
import TableMaterial from "./TableMaterial";

export default function Table({...props}) 
{
    const { nodes, scene } = useLoader(GLTFLoader, `${BASE}/exhibit/table.glb`)

    return(
        <>
            <mesh geometry={nodes.Console_Table.geometry} position={props.position} rotation={[0,Math.PI/2,0]} scale={1.3}>
                <TableMaterial />
            </mesh>
        </>
    )
}