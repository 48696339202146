import { useTexture } from "@react-three/drei"
import { useEffect, useState } from "react"
import { sRGBEncoding, TextureLoader } from "three"
import { BASE } from "./actions"
import useStore from "./store"

export default function BookMaterial() {

    const texture = useTexture(`${BASE}/exhibit/textures/cover.jpg`)
    texture.flipY = false

    const inBackRoom = useStore(state => state.inBackRoom)
    return(
        <meshLambertMaterial
            map={texture}
            color={inBackRoom?"white":"black"}
        />
    )
}