import create from "zustand";

const useStore = create(set => ({
    mainVolume: .1,
    setMainVolume: (newValue) => set({mainVolume: newValue}),
    audioStarted: false,
    setAudioStarted: (newValue) => set({audioStarted: newValue}),
    bookEnabled: false,
    setBookEnabled: (newValue) => set({bookEnabled: newValue}),
    firstInteractionDone: false,
    setFirstInteractionDone: (newValue) => set({firstInteractionDone: newValue}),
    inBackRoom: false,
    setInBackRoom: (newValue) => set({inBackRoom: newValue}),
    isLocked: false,
    setIsLocked: (newValue) => set({isLocked: newValue}),
    videoUrl: "",
    setVideoUrl: newValue => set({videoUrl: newValue}),
    currentOverlay: "tutorial",
    setCurrentOverlay: newValue => set({currentOverlay: newValue}),
    hovering: false,
    setHovering: newValue => set({hovering: newValue}),
    language: "de",
    setLanguage: newValue => set({language: newValue})
})
);

export default useStore;